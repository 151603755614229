import React, { Component } from 'react'

export default class Phone extends Component {
  render() {
    return (
      <div class="hero min-h-screen ">
        <div class="hero-content flex-col lg:flex-row-reverse animate__animated animate__fadeIn">
          <div class="mockup-phone">
            <div class="camera"></div>
            <div class="display">
              <div class="artboard artboard-demo phone-1">
              <code>&lt;Hello World!/&gt;</code>
              </div>
            </div>
          </div>
          <div>
            <h1 class="text-5xl font-bold">We Create Mobile Apps!</h1>
            <p class="py-6">
              Whether it's Android or Apple, Imaginex can create a mobile application that can get your customers going!
              <br/>
              Our React Native applications change the game for modern day businesses.
            </p>
            <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1"><button class="btn btn-primary">Learn More</button></a>
          </div>
        </div>
      </div>
    );
  }
}
