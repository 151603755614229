import LoginForm from 'components/LoginForm/LoginForm'
import React, { Component } from 'react'

export default class Login extends Component {
  render() {
    return (
      <div>
        <LoginForm/>
      </div>
    )
  }
}
