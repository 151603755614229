import Cards from 'components/General-Elements/Cards/Cards';
import Obj from 'assets/json/Team.json'
import React, { Component } from 'react'

export default class Team extends Component {
  render() {
    return (
      <div class="hero min-h-screen pb-10 animate__animated animate__fadeIn">
        <div class="flex flex-col w-full">
          <h1 class="text-6xl font-bold pb-6 text-center">This is Our Team</h1>

          <div class="flex flex-col w-full lg:flex-row">
            <div class="flex flex-col w-full lg:flex-row">
              <div class="grid flex-grow rounded-box place-items-center pb-4">
                <Cards
                  title={Obj.Officers.Alcinder.Name}
                  image={Obj.Officers.Alcinder.Image}
                  role={Obj.Officers.Alcinder.Role}
                  email={Obj.Officers.Alcinder.Email}
                  linkedin={Obj.Officers.Alcinder.Linkedin}
                />
              </div>

              <div class="grid flex-grow  rounded-box place-items-center pb-4">
                <Cards
                  title={Obj.Sale.Woody.Name}
                  image={Obj.Sale.Woody.Image}
                  role={Obj.Sale.Woody.Role}
                  email={Obj.Sale.Woody.Email}
                  linkedin={Obj.Sale.Woody.Linkedin}
                />
              </div>
            </div>

            <div class="flex flex-col w-full lg:flex-row">
              <div class="grid flex-grow  rounded-box place-items-center pb-4">
                <Cards
                  title={Obj.Developers.Kris.Name}
                  image={Obj.Developers.Kris.Image}
                  role={Obj.Developers.Kris.Role}
                  email={Obj.Developers.Kris.Email}
                  linkedin={Obj.Developers.Kris.Linkedin}
                />
              </div>

              <div class="grid flex-grow  rounded-box place-items-center pb-4">
                <Cards
                  title={Obj.Marketing.Yeshua.Name}
                  role={Obj.Marketing.Yeshua.Role}
                  image={Obj.Marketing.Yeshua.Image}
                />
              </div>
            </div>
          </div>
          <div class="p-3"></div>
          <div class="flex flex-col w-full lg:flex-row">
           
            <div class="grid flex-grow rounded-box place-items-center pb-4">
              <Cards
                title={Obj.Developers.Kevin.Name}
                role={Obj.Developers.Kevin.Role}
                image={Obj.Developers.Kevin.Image}
                email={Obj.Developers.Kevin.Email}
                linkedin={Obj.Developers.Kevin.Linkedin}
              />{" "}
            </div>

            <div class="grid flex-grow rounded-box place-items-center pb-4">
              <Cards
                title={Obj.Developers.Tanner.Name}
                role={Obj.Developers.Tanner.Role}
                image={Obj.Developers.Tanner.Image}
                email={Obj.Developers.Tanner.Email}
                linkedin={Obj.Developers.Tanner.Linkedin}
              />

            </div>
          </div>
          
        </div>
      </div>
    );
  }
}
