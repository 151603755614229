import React, { Component } from 'react'

export default class Hero extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <div class="hero-content text-center animate__animated animate__fadeIn">
          <div class="max-w-md">
            <h1 class="text-7xl font-bold">Welcome to Imaginex</h1>
            <p class="py-6">
              We create digital solutions for companies to gain a competitive edge on large scale corporations. 
            </p>
            <a href="/about"><button class="btn btn-primary">Learn More</button></a>
          </div>
        </div>
      </div>
    );
  }
}
