import Phone from 'components/WebObjects/Phone/Phone'
import WebSec from 'components/WebObjects/WebSec/WebSec'
import WebTop from 'components/WebObjects/WebTop/WebTop'
import React, { Component } from 'react'

export default class Web extends Component {
  render() {
    return (
      <div>
        <WebTop/>
        <Phone/>
        <WebSec/>
      </div>
    )
  }
}
