import React, { Component } from "react";

export default class Detail extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 animate__animated animate__fadeIn">
          <div class="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
            <div class="p-8 border-b sm:border-r">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Digital Innovation</h6>
                <p class="mb-3 text-sm text-gray-900">
                  Our company is not afraid to learn new programming languages
                  in order to acomplish a goal. We look for the best and make
                  the best no matter what obstacles we face. Innovation is hard
                  and we work hard to get it.
                </p>
              </div>
            </div>
            <div class="p-8 border-b lg:border-r">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">
                  Compounded Experience
                </h6>
                <p class="mb-3 text-sm text-gray-900">
                  Our developers and leaders come from different areas within the
                  cryptocurrency space. From application development, smart contract
                  deployment, to general cryptocurrency teaching, our staff have
                  had our feet in cryptocurrency for a while.
                </p>
              </div>
            </div>
            <div class="p-8 border-b sm:border-r lg:border-r-0">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Success Oriented</h6>
                <p class="mb-3 text-sm text-gray-900">
                  We care about what you are trying to do for the crypto space
                  and want to see you succeed. If your project is innovative
                  enough, we might even join you in making it grow.
                </p>
              </div>
            </div>
            <div class="p-8 border-b lg:border-b-0 lg:border-r">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">
                  Communicative and Connective
                </h6>
                <p class="mb-3 text-sm text-gray-900">
                  Our team are client-oriented via communication. If you need
                  a problem to be solved, just send us an email
                  or give us a call. We would like to talk with you about anything
                  crypto-related.
                </p>
              </div>
            </div>
            <div class="p-8 border-b sm:border-b-0 sm:border-r">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Data Oriented</h6>
                <p class="mb-3 text-sm text-gray-900">
                  We aim to provide actions based on research and data. Our
                  services always have an element of research to show how tools
                  can be utilized to maximize growth and development in any
                  sector of business.
                </p>
              </div>
            </div>
            <div class="p-8">
              <div class="max-w-md text-center">
                <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Welcoming Options</h6>
                <p class="mb-3 text-sm text-gray-900">
                  The decentralized space is fairly new to the world and we
                  believe that coming into the space should be as easy as
                  possible. That is why when you work with us, we not only
                  create your solution, but also provide you with many different
                  pathways you can take.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
