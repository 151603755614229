import React, { Component } from "react";

export default class MarketTop extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="flex flex-col lg:flex-row animate__animated animate__fadeIn">
            <div class="max-w-xl pr-16 mx-auto mb-10">
              <h5 class="mb-6 text-3xl font-extrabold leading-none">
                Need Help Getting Your Business Out There?
              </h5>
              <p class="mb-6 text-gray-900">
                Imaginex can help you establish your company on the Internet and
                build your social presence logically and systematically. Have
                everything you need to manage your digital presence at your
                fingertips.
              </p>
              <div class="flex items-center">
                <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1">
                  <button type="submit" class="btn btn-secondary">
                    Get started
                  </button>
                </a>
              </div>
            </div>
            <div class="grid gap-5 row-gap-5 sm:grid-cols-2">
              <div class="max-w-md">
                <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Upload Automation</h6>
                <p class="text-sm text-gray-700">
                  Find the best way to get your uploads to multiple platforms
                  easily and effectively. Reduce the complexity of having to
                  organize what, when, and where your company will present
                  itself.
                </p>
              </div>
              <div class="max-w-md">
                <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">Brand Building</h6>
                <p class="text-sm text-gray-700">
                  Begin to establish who you are and what you do. Define
                  marketing goals and target audiences. Explore new customer and
                  cost-based solutions to onboard them.
                </p>
              </div>
              <div class="max-w-md">
                <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">
                  Data-Driven Strategy
                </h6>
                <p class="text-sm text-gray-700">
                  Learn from your customers and improve how you market to them.
                  Allow us to construct visualizations for how your company
                  relates to your audience.
                </p>
              </div>
              <div class="max-w-md">
                <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </div>
                <h6 class="mb-2 font-semibold leading-5">
                  Customized Solutions
                </h6>
                <p class="text-sm text-gray-700">
                  Find what best works for you. Navigate with us how you can
                  maximize a positive relationship with your customer base.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
