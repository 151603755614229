import React, { Component } from "react";

export default class MarketingThree extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 animate__animated animate__fadeIn">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-5xl text-2xl font-bold title-font text-gray-900">
              Our Programming Makes Marketing Easy
            </h1>
          </div>
          <div class="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
            <div class="p-8 bg-white border rounded shadow-sm">
              <h1
                
                class="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Program Automation
              </h1>
              <p class="mb-5 text-gray-700">
                If there is something that is going to be done repeatedly, let's make a program to take care of it. Our software developers look and strive for automation and safety.
              </p>
              <div class="flex items-center">
   
                  <img
                    src="../images/Alcinder.jpeg"
                    alt="avatar"
                    class="object-cover w-10 h-10 rounded-full shadow-sm mr-3"
                  />
        
                <div>
                  <h1
                    
                    class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Alcinder Lewis
                  </h1>
                  <p class="text-sm font-medium leading-4 text-gray-600">
                  Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
            <div class="p-8 bg-white border rounded shadow-sm">
              <h1
                
                class="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Make Actions from Data
              </h1>
              <p class="mb-5 text-gray-700">
                Determine the best move for a company from its audience. Let us help you learn them and make wise, data-driven decisions. 
              </p>
              <div class="flex items-center">
                
                  <img
                    src="../images/Kris.jpeg"
                    alt="avatar"
                    class="object-cover w-10 h-10 rounded-full shadow-sm mr-3"
                  />
               
                <div>
                  <h1
                    
                    class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Kris Valdez
                  </h1>
                  <p class="text-sm font-medium leading-4 text-gray-600">
                  Chief Operations Officer
                  </p>
                </div>
              </div>
            </div>
            <div class="p-8 bg-white border rounded shadow-sm">
              <h1
                
                class="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Train Others to Do It
              </h1>
              <p class="mb-5 text-gray-700">
                If we provided you with programs, we'll show you how to use them. Our documentation is always well tested and customized.
              </p>
              <div class="flex items-center">
                  <img
                    src="../images/Woody.jpeg"
                    alt="avatar"
                    class="object-cover w-10 h-10 rounded-full shadow-sm mr-3"
                  />
                <div>
                  <h1
                    
                    class="font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Woody Humes
                  </h1>
                  <p class="text-sm font-medium leading-4 text-gray-600">
                  Chief of Sales
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
