import Web3 from 'components/Web3Objects/Web3Display/Web3'
import Statement from 'components/Web3Objects/Statement/Statement'
import React, { Component } from 'react'
import Detail from 'components/Web3Objects/Detail/Detail'

export default class Crypto extends Component {
  render() {
    return (
      <div>
        <Web3/>
        <Statement/>
        <Detail/>
      </div>
    )
  }
}
