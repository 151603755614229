import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
        <footer class="footer p-10 bg-base-200 text-base-content">
        <div>
          <span class="footer-title">Services</span> 
          <a  href="/Web3/" class="link link-hover">Web3 Solutions</a> 
          <a  href="/Web/" class="link link-hover">Web Development</a> 
          <a  href="/Marketing/" class="link link-hover">Marketing</a> 
        </div> 
        <div>
          <span class="footer-title">Company</span> 
          <a href="/About/" class="link link-hover">About us</a> 
          <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1" class="link link-hover">Contact</a> 
        </div> 
        <div>
          <span class="footer-title">Legal</span> 
          <a  href="/Terms/" class="link link-hover">Terms of use</a> 
          <a  href="/Policy/" class="link link-hover">Privacy policy</a> 
          <a  href="/Cookies/" class="link link-hover">Cookie policy</a>
        </div> 
        <div>
          <span class="footer-title">Newsletter</span> 
          <div class="form-control w-80">
            <label class="label">
              <span class="label-text">Enter your email address</span>
            </label> 
            <div class="relative">
              <input type="text" placeholder="username@site.com" class="input input-bordered w-full pr-16"  disabled/> 
              <button class="btn btn-primary absolute top-0 right-0 rounded-l-none" disabled>Subscribe</button>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
