import React, { Component } from "react";

export default class LoginForm extends Component {
  render() {
    return (
      <div className="hero min-h-screen">
        <div class="card w-96 bg-base-100 shadow-xl border animate__animated animate__fadeIn">
          <div class="card-body">
            <div>
              <form action="?">
                <label
                  class="card-title text-center justify-center pb-2"
                  htmlFor="Title"
                >
                  <h1 class="text-5xl">Login</h1>
                </label>

                <div class="p-4">
                  <div class="pb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      class="input input-bordered input-accent w-full max-w-xs"
                      disabled
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Password"
                      class="input input-bordered input-accent w-full max-w-xs"
                      disabled
                    />
                  </div>
                </div>

                <div class="card-actions justify-center">
                  <input
                    type="submit"
                    class="btn btn-secondary"
                    disabled
                    value="Login"
                  ></input>
                  
                </div>
              </form>
            </div>
            <div class="card-actions justify-center">
              <a href="/Forgot/" class="bold center underline">
                Forgot Password
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

