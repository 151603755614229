import Team from 'components/About-Elements/Team/Team'
import Title from 'components/About-Elements/Title/Title'
import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <Title/>
      </div>
    )
  }
}
