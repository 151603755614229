import React, { Component } from "react";

export default class Web3 extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <section class=" body-font">
          <div class="container px-5 py-24 mx-auto">
            <div class="flex flex-wrap w-full mb-20 animate__animated animate__fadeInUp">
              <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
                <div class="text-5xl font-bold max-w-md pb-5">
                  <h1>
                    Let Us Handle Your
                    <br />
                    Next Crypto Project
                  </h1>
                </div>
              </div>
              <p class="lg:w-1/2 w-full leading-relaxed ">
                Whatever project you are looking to make within the
                crypto-space, Imaginex will be able to help. Our team has
                several developers experienced with cryptocurrency related
                programming. Schedule a meeting to have your business world
                become web3 integrated.
              </p>
            </div>
            <div class="flex flex-wrap -m-4">
              <div class="xl:w-1/4 md:w-1/2 p-4 animate__animated animate__fadeIn animate__delay-2s">
                <div class="border p-6 rounded-lg">
                  <img
                    class="p-4 mb-6"
                    src="../images/hand.png"
                    alt="content"
                  />

                  <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                    Web3 Consultation
                  </h2>
                  <p class="leading-relaxed text-base">
                    Let's find the best way to make your Web3 dreams come true.
                    Imaginex can empower you with the data and research you need
                    to feel confident in your Web3 journey.
                  </p>
                </div>
              </div>
              <div class="xl:w-1/4 md:w-1/2 p-4 animate__animated animate__fadeIn animate__delay-3s">
                <div class="border p-6 rounded-lg">
                  <img
                    class="p-4 mb-6"
                    src="../images/token.png"
                    alt="content"
                  />

                  <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                    Token & Smart Contract Developement
                  </h2>
                  <p class="leading-relaxed text-base">
                    Programming smart contracts can empower your next steps
                    towards establishment. We make a simple pathway to get
                    contracts on chain.
                  </p>
                </div>
              </div>
              <div class="xl:w-1/4 md:w-1/2 p-4 animate__animated animate__fadeIn animate__delay-4s">
                <div class="border p-6 rounded-lg">
                  <img
                    class="p-4 mb-6"
                    src="../images/Exchange.png"
                    alt="content"
                  />

                  <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                    Exchange Software Development
                  </h2>
                  <p class="leading-relaxed text-base">
                    Looking to make the next Coinbase or Crypto.com? Let us help
                    build the groundwork for a scalable, responsive, and
                    powerful application. Our operations create industries and pioneer innovation.
                  </p>
                </div>
              </div>
              <div class="xl:w-1/4 md:w-1/2 p-4 animate__animated animate__fadeIn animate__delay-5s">
                <div class="border p-6 rounded-lg">
                  <img
                    class="p-4 mb-6"
                    src="../images/market.png"
                    alt="content"
                  />
                  <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Decentralized Company Organization
                  </h2>
                  <p class="leading-relaxed text-base">
                    If you do not know where to start but you want to make your
                    idea big, we can help. Our team has made crypto projects and
                    can help you do the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
