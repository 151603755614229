import MarketingRoad from 'components/MarketingObjects/MarketingRoad/MarketingRoad'
import MarketingThree from 'components/MarketingObjects/MarketingThree/MarketingThree'
import MarketTop from 'components/MarketingObjects/MarketTop/MarketTop'
import React, { Component } from 'react'

export default class Marketing extends Component {
  render() {
    return (
      <div>
        <MarketTop/>
        <MarketingRoad/>
        <MarketingThree/>
      </div>
    )
  }
}
