import React, { Component } from "react";

export default class WebTop extends Component {
  render() {
    return (
      <div class=" min-h-screen">
        <div class="flex flex-col items-center justify-center px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:pt-32 md:px-0">
          <div class="flex flex-col items-center max-w-2xl md:px-8 pb-20 animate__animated animate__fadeIn">
            <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 ">
              <h2 class="max-w-lg mb-6 font-sans text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-5xl md:mx-auto">
                We Create The Web
              </h2>
              <p class="text-base text-gray-700 md:text-lg">
                Web Application and Web Developement is Imaginex's superpower.
                We provide digital solutions to all matters on the Internet.
              </p>
            </div>

            <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1"><button class="btn btn-secondary animate__animated animate__fadeIn">Learn More</button></a>
          
          </div>
          <img
            src="../images/half-browser.png"
            class="w-full max-w-screen-sm mx-auto rounded shadow-2xl md:w-auto lg:max-w-screen-md animate__animated animate__fadeIn"
            alt=""
          />
        </div>
      </div>
    );
  }
}
