import React, { Component } from "react";

export default class Statement extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <section class="animate__animated animate__fadeIn">
          <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
            <img
              class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded border"
              alt="hero"
              src="../images/plan.jpg"
            />
            <div class="text-center lg:w-2/3 w-full">
              <h1 class="sm:text-5xl font-bold mb-4">
                Talk With Us About Crypto
              </h1>
              <p class="leading-relaxed">
                If you are confused about what steps or actions you need to
                take, we can help. Our company is oriented in helping the
                crypto-community evolve and grow. We believe that
                decentralization practices and commodities will be the business
                of the future.
              </p>
              <br />
              <p class="font-bold mb-8">A future meant for everyone.</p>
              <div class="flex justify-center">
                <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1">
                  <button class="inline-flex text-white btn btn-secondary text-lg">
                    Schedule Today
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
