import React, { Component } from "react";

export default class Title extends Component {
  render() {
    return (
      <div class="hero min-h-screen ">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 animate__animated animate__fadeInUp">
          <div class="grid gap-5 row-gap-10 lg:grid-cols-2">
            <div class="flex flex-col justify-center">
              <div class="max-w-xl mb-6">
                <h2 class="max-w-lg mb-6 font-sans text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
                  Our Mission is to
                  <br class="hidden md:block" />
                  <span class="relative px-1">
                    <div class="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400"></div>
                    <span class="relative inline-block text-deep-purple-accent-400">
                      Change the World
                    </span>
                  </span>
                </h2>
                <p class="text-base text-gray-700 md:text-lg">
                  At Imaginex, our slogan is that we provide corporate level
                  tools without corporate level cost. We strive to allow small
                  businesses to compete with companies like Amazon via
                  innovative software development and automation.
                </p>
              </div>
              <p class="mb-4 text-sm font-bold tracking-widest uppercase">
                How do we accomplish this?
              </p>
              <div class="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
                <ul class="space-y-3">
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Innovative Programming Practices
                  </li>
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Payment Plans
                  </li>
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Artificial Intelligence Integration
                  </li>
                </ul>
                <ul class="space-y-3">
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Automation Workflows
                  </li>
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Productive Collaborations
                  </li>
                  <li class="flex">
                    <span class="mr-1">
                      <svg
                        class="w-5 h-5 mt-px text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        ></polygon>
                      </svg>
                    </span>
                    Pinpoint Problem-Oriented Solutions
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div class="mockup-window border bg-base-300">
                <div class="flex justify-center px-4 py-32 bg-base-200 bold text-2xl">
                  <code>&lt;Hello World!/&gt;</code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
