import React from 'react';

const Construct = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://forms.gle/XxCgw3zS8AvnVaQbA';
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <h1 className="text-8xl font-bold text-gray-900 mb-4">Imaginex </h1>
      <h2 className="text-4xl font-thin text-gray-900 mb-4">Grants & Software </h2>
      <h3 className="text-2xl font-bold text-gray-900 mb-4">Website is under reconstruction</h3>
     
      <button
        onClick={handleButtonClick}
        className="btn bg-[#618A3D] text-white font-bold py-2 px-4  hover:bg-[#75a64b] outline-none"
      >
        Contact Us
      </button>
    </div>
  );
};

export default Construct;
