import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Nav from "components/General-Elements/Nav/Nav";
import './App.css';
import Home from "pages/public/Home/Home";
import Footer from "components/General-Elements/Footer/Footer";
import Coming from "pages/public/Coming/Coming" 
import About from "pages/public/About/About";
import Login from "pages/public/Auth/Login/Login";
import Forgot from "pages/public/Auth/Forgot/Forgot";
import Crypto from "pages/public/Services/Crypto/Crypto";
import Web from "pages/public/Services/Web/Web";
import Marketing from "pages/public/Services/Marketing/Marketing";

function App() {
  return (
    <Router>
    <Nav/>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path="*" element={<Home/>}></Route>
      {/* <Route path='/Login' element={<Login/>}></Route>
      <Route path='/About' element={<About/>}></Route>
      <Route path='/Forgot' element={<Forgot/>}></Route>
      <Route path='/Web3' element={<Crypto/>}></Route>
      <Route path='/Web' element={<Web/>}></Route>
      <Route path='/Marketing' element={<Marketing/>}></Route> */}
    </Routes>
    <Footer/>
  </Router>
  );
}

export default App;
