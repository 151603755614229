import React, { Component } from "react";

export default class MarketingRoad extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 animate__animated animate__fadeIn">
          <div class="grid gap-6 row-gap-10 lg:grid-cols-2">
            <div class="lg:py-6 lg:pr-16">
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div>
                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        class="w-4 text-gray-600"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          stroke-miterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        ></line>
                        <polyline
                          fill="none"
                          stroke-miterlimit="10"
                          points="19,15 12,22 5,15"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                  <div class="w-px h-full bg-gray-300"></div>
                </div>
                <div class="pt-1 pb-8">
                  <p class="mb-2 text-lg font-bold">Step 1</p>
                  <p class="text-gray-700">
                    We hold a consultation and determine the problem points of
                    your digital marketing and how they can be improved. Let us
                    learn your company and its connection with its target
                    audience.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div>
                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        class="w-4 text-gray-600"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          stroke-miterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        ></line>
                        <polyline
                          fill="none"
                          stroke-miterlimit="10"
                          points="19,15 12,22 5,15"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                  <div class="w-px h-full bg-gray-300"></div>
                </div>
                <div class="pt-1 pb-8">
                  <p class="mb-2 text-lg font-bold">Step 2</p>
                  <p class="text-gray-700">
                    Our team constructs your a digital marketing plan from the
                    research and data we gather on your target audience. We also
                    find alternative pathways within the plan for scalability
                    and expansion.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div>
                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        class="w-4 text-gray-600"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          stroke-miterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        ></line>
                        <polyline
                          fill="none"
                          stroke-miterlimit="10"
                          points="19,15 12,22 5,15"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                  <div class="w-px h-full bg-gray-300"></div>
                </div>
                <div class="pt-1 pb-8">
                  <p class="mb-2 text-lg font-bold">Step 3</p>
                  <p class="text-gray-700">
                    Another consultation is held and we setup actions that
                    Imaginex can take to make the digital
                    material for your company to utilize.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div>
                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        class="w-4 text-gray-600"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          stroke-miterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        ></line>
                        <polyline
                          fill="none"
                          stroke-miterlimit="10"
                          points="19,15 12,22 5,15"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                  <div class="w-px h-full bg-gray-300"></div>
                </div>
                <div class="pt-1 pb-8">
                  <p class="mb-2 text-lg font-bold">Step 4</p>
                  <p class="text-gray-700">
                    After the material is created, we will meet once more to
                    conclude and overview.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div>
                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        class="w-6 text-gray-600"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <polyline
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          points="6,12 10,16 18,8"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="mb-2 text-lg font-bold">Success</p>
                  <p class="text-gray-700"></p>
                </div>
              </div>
            </div>
            <div class="relative">
              <img
                class="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
                src="../images/tech.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
