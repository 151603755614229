import React, { Component } from "react";

export default class Nav extends Component {
  render() {
    return (
      <div class="navbar bg-base-100 z-40">
        <div class="navbar-start">
          <div class="dropdown">
            <label tabindex="0" class="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <a href="/About">About Us</a>
              </li>
              <li tabindex="0">
                <a class="justify-between">
                  Our Services
                  <svg
                    class="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                  </svg>
                </a>
                <ul class="p-2 bg-secondary text-white">
                  <li>
                    <a href="/Web3/">Grant Services</a>
                  </li>
                  <li>
                    <a href="/Web/">Software Services</a>
                  </li>
                  <li>
                    <a href="/Marketing/">Consultation Services</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="https://forms.gle/XxCgw3zS8AvnVaQbA">Contact Us</a>
              </li>
            </ul>
          </div>
          <a href="/" class="btn btn-ghost normal-case text-3xl">
            Imaginex
          </a>
        </div>
        <div class="navbar-center hidden lg:flex">
          <ul class="menu menu-horizontal p-0">
            <li>
              <a href="/About">About Us</a>
            </li>
            <li tabindex="0">
              <a>
                Our Services
                <svg
                  class="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                </svg>
              </a>
              <ul class="p-2 bg-secondary text-white z-20">
              <li>
                    <a href="/Web3/">Grant Services</a>
                  </li>
                  <li>
                    <a href="/Web/">Software Services</a>
                  </li>
                  <li>
                    <a href="/Marketing/">Consultation Services</a>
                  </li>
              </ul>
            </li>
            <li>
              <a href="https://forms.gle/XxCgw3zS8AvnVaQbA">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="navbar-end">
          <a href="/Login/" class="btn bg-[#618A3D] text-white font-bold py-2 px-4  hover:bg-[#75a64b] outline-none">
            Login
          </a>
        </div>
      </div>
    );
  }
}
