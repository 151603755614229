import React, { Component } from 'react'

export default class WebSec extends Component {
  render() {
    return (
      <div class="hero min-h-screen ">
        <div class="hero-content flex-col-reverse lg:flex-row-reverse animate__animated animate__fadeIn">
          <div>
            <h1 class="text-5xl font-bold">Custom Web Applications</h1>
            <p class="py-6">
              Web applications like the one you are using right now are Imaginex's bread and butter.
              <br />
              Through our web applications, companies are able to experience the true power of the Internet.
            </p>
            <a href="https://forms.monday.com/forms/bc4fb30959fd3a2fb1f451aa6e0780ef?r=use1"><button class="btn btn-secondary">Learn More</button></a>
          </div>
          <div class='px-3'></div>
          <div class="mockup-code ">
            <pre data-prefix="$">
              <code>npm create-react-app</code>
            </pre>
            <pre data-prefix=">">
              <code>installing...</code>
            </pre>
            <pre data-prefix=">" class="text-success">
              <code>Done!</code>
            </pre>
          </div>
        </div>
      </div>
    );
  }
}
