import React, { Component } from 'react'

export default class Coming extends Component {
  render() {
    return (
      <div class="hero min-h-screen">
        <h1 class="text-center text-7xl font-bold">Coming Soon</h1>
      </div>
    );
  }
}
